@import "../node_modules/video-react/dist/video-react.css";

html, body, #root {
  height: 100%;
  background-color: #F6F6F6;
}

div {
  box-sizing: border-box;
}

.firebase-emulator-warning {
  display: none !important;
}