.modal-dialog {
    padding: 8px;
    background-color: white;
    position: fixed;
    overflow-y: scroll;
    display: block;
    top: 50px;
    bottom: 0;
    right: -480px;
    height: vh;
    z-index: 99;
    -webkit-box-shadow: -2px 0px 5px -1px rgba(0,0,0,0.77);
    -moz-box-shadow: -2px 0px 5px -1px rgba(0,0,0,0.77);
    box-shadow: -2px 0px 5px -1px rgba(0,0,0,0.77);
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
    opacity: 0;
  }
  
  .modal-dialog.show {
    right: 0px;
    width: 480px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
    opacity: 1;
  }